import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "container-fluid position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ProjectNav = _resolveComponent("ProjectNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_layout_main = _resolveComponent("layout-main")!
  const _component_orbiter_loader = _resolveComponent("orbiter-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isSignedIn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Header),
          _createVNode(_component_layout_main, {
            sidebar: $setup.sidebar,
            main: $setup.main
          }, {
            sidebar: _withCtx(() => [
              _createVNode(_component_ProjectNav, { "onLayout:change": $setup.onLayoutChange }, null, 8, ["onLayout:change"])
            ]),
            main: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }, 8, ["sidebar", "main"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_view)
          ])
        ])),
    _createVNode(_component_orbiter_loader)
  ]))
}