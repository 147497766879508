
import { defineComponent, PropType, ref, watch } from "vue";
import { Task } from "@/types";

export default defineComponent({
  name: "TaskNotification",
  props: {
    task: {
      type: Object as PropType<Task>,
      required: true,
    },
  },
  setup(props) {
    const isNotified = ref<boolean>(false);
    const message = ref<string>("");

    watch(
      () => props.task._changed,
      (val) => {
        isNotified.value = val;
      }
    );

    return {
      isNotified,
      message,
    };
  },
});
