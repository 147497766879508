import { defineStore } from "pinia";
import { api } from "@/api/client";
import { Assignee } from "@/types";

export const useAssigneeStore = defineStore("assigneeStore", {
  state: () => ({
    assignees: undefined as Array<Assignee> | undefined,
  }),

  actions: {
    async getProjectAssignees(projectId: number) {
      this.assignees = await api.get<Array<Assignee>>(
        `projects/${projectId}/assignees/`
      );
      //return data;
    },
  },
});
