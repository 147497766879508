import { defineStore } from "pinia";
import { sharedApi } from "@/api/client";
import { User } from "@/types";
import { computed, reactive, toRefs, watch } from "vue";
import { useSettings } from "@/composables/use-settings";

export const useUserStore = defineStore("user", () => {
  const settings = useSettings();

  const state = reactive({
    loading: true,
    user: {} as User,
    avatarCache: {} as Record<string, string>,
  });

  const isSignedIn = computed(() => state.user.pk !== undefined);
  const displayName = computed(() => state.user.display_name);

  const clear = () => {
    state.user = {} as User;
  };

  const fetchAvatar = async (userPk: number) => {
    // TODO: better caching
    if (state.avatarCache[userPk] !== undefined) {
      return Promise.resolve(state.avatarCache[userPk]);
    }
    return (state.avatarCache[userPk] = await sharedApi.get<string>(
      `user/${userPk}/avatar/`
    ));
  };

  const fetchUser = async (userPk?: number | null) => {
    const url = userPk !== undefined ? `user/${userPk}/` : "user/";
    state.user = await sharedApi.get<User>(url);
    state.loading = false;
  };

  watch(
    () => state.user?.account?.pk,
    (account) => {
      settings.tenant.value = account?.toString();
    }
  );

  return {
    ...toRefs(state),

    isSignedIn,
    displayName,

    fetchAvatar,
    fetchUser,
    clear,
  };
});

export default useUserStore;
