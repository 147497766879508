import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "assignee-picker" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("select", {
      class: "project-filter-button",
      value: _ctx.modelValue,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', Number.parseInt($event.target.value))))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignees.users, (assignee) => {
        return (_openBlock(), _createElementBlock("option", {
          key: assignee.pk,
          value: assignee.pk
        }, _toDisplayString(assignee.display_name), 9, _hoisted_3))
      }), 128))
    ], 40, _hoisted_2)
  ]))
}