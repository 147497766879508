import { defineStore } from "pinia";
import { Universe } from "@/types";

export const useGlobalStore = defineStore("global", {
  state: () => ({
    error: {},
    next: {
      path: "/",
    },
    universe: {} as Universe | undefined,
  }),

  actions: {},
});
