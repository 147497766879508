
import { defineComponent } from "vue";

export default defineComponent({
  name: "TaskPriority",
  props: {
    modelValue: {
      type: Number,
    },
  },
  setup() {
    return {};
  },
});
