import { defineStore } from "pinia";
import { AppConfig } from "@/types";
import { reactive, toRefs, watch } from "vue";

export const useAppStore = defineStore("appStore", () => {
  const state = reactive({
    config: getConfigFromLocalStorage(),
    loading: false,
  });

  function getConfigFromLocalStorage(): AppConfig {
    const conf = window.localStorage.getItem("lc_config");
    if (conf) {
      return JSON.parse(conf) as AppConfig;
    }
    return state?.config || ({} as AppConfig);
  }

  watch(
    () => state.config,
    (config) => {
      window.localStorage.setItem("lc_config", JSON.stringify(config));
    },
    { deep: true }
  );

  state.config.sharedAPIBaseURL = process.env.VUE_APP_API_URL;

  return {
    ...toRefs(state),
  };
});
