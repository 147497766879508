
import { defineComponent } from "vue";

export default defineComponent({
  name: "assignee-picker",
  props: {
    assignees: {
      type: Object,
    },
    modelValue: {
      type: Number,
    },
  },
});
