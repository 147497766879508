
import { computed, defineComponent, PropType } from "vue";
import { Assignee, ProjectAssignees } from "@/types";

export default defineComponent({
  name: "TaskAssigneeSelect",
  props: {
    assignees: { type: Object as PropType<ProjectAssignees>, required: true },

    modelValue: { type: Number, required: false },
  },
  setup(props, { emit }) {
    const assignee = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    return {
      assignee,
    };
  },
});
