import { User } from "@/types/index";

export const messages = {
  en: {
    event: {
      status_changed:
        "{owner} changed status of task {task} from {from} to {to}",
      assignee_changed:
        "{owner} changed the assignee of task {task} from {from} to {to}",
      started_working_on: "{owner} started working on {task}",
      stopped_working_on: "{owner} stopped working on {task}",
    },
    task: {},
  },
};

type EventPayload = {
  from?: Record<string, unknown> | undefined;
  to?: Record<string, unknown> | undefined;
  owner: User;
};

export type Event = {
  source: number;
  name: "status_changed" | "assignee_changed";
  payload: EventPayload;
};

export type Activity = {
  pk: number;
  event: Event;
};

export type Notification = {
  pk: number;
  user: number;
  seen: boolean;
  activity: Activity;
};

export type NotificationMessage = {
  notifications: Array<Notification>;
};
