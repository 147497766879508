
import { EditorContent, useEditor } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import { defineComponent, onMounted, ref, toRef, watch } from "vue";

export default defineComponent({
  name: "Editor",
  components: {
    EditorContent,
  },
  emits: ["update:modelValue", "editor:debounce"],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    autoHide: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const content = toRef(props, "modelValue");
    const minimized = ref(props.autoHide);

    const editor = useEditor({
      extensions: [
        StarterKit.configure({}),
        Underline,
        Placeholder.configure({
          placeholder: "Add a comment",
        }),
      ],
      onUpdate: () => {
        emit("update:modelValue", editor.value?.getHTML());
      },
      onFocus: () => {
        minimized.value = false;
      },
      onBlur: () => {
        minimized.value = props.autoHide;
      },
    });

    const getToolbarButtonClass = (format: string) => {
      return { active: editor.value?.isActive(format) };
    };

    onMounted(() => {
      editor.value?.commands.setContent(content.value, false);
    });

    watch(content, (value) => {
      const isSame = editor.value?.getHTML() === value;

      if (isSame) {
        return;
      }

      editor.value?.commands.setContent(value, false);
    });

    const formatBold = () => {
      editor.value?.chain().focus().toggleBold().run();
    };

    const formatUnderline = () => {
      editor.value?.chain().focus().toggleUnderline().run();
    };

    return {
      // Refs
      editor,
      minimized,

      // Actions
      formatBold,
      formatUnderline,

      // Methods
      getToolbarButtonClass,
    };
  },
});
