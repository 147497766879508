
import { defineComponent, ref, watch } from "vue";
import { useNotificationStore } from "@/store/notifications";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Header",
  setup() {
    const notificationStore = useNotificationStore();
    const { unseenNotificationsCount, notifications } =
      storeToRefs(notificationStore);

    const activitiesVisible = ref<boolean>(false);

    const toggleActivities = () => {
      activitiesVisible.value = !activitiesVisible.value;
    };

    notificationStore.fetchUnseenCount();

    watch(
      () => activitiesVisible.value,
      (val) => {
        if (val) {
          notificationStore.fetchUnseen();
        }
      }
    );

    watch(
      () => unseenNotificationsCount.value,
      () => {
        if (activitiesVisible.value === true) {
          notificationStore.fetchUnseen();
        }
      }
    );

    return {
      unseenNotificationsCount,
      activitiesVisible,
      notifications,
      // Methods
      toggleActivities,
    };
  },
});
