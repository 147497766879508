const SEALWORKS_LOGO = `
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⢀⢠⣤⡶⠖⠒⠂⠐⠀⠄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⢀⣄⠁⠈⠁⣤⣤⣤⣤⣤⣄⣀⠈⠢⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣠⣴⣶⣶⣶⣶⣤⣀⠀⠀⢰⣶⣶⣶⣶⣶⣶⣶⣶⠀⠀⠀⠀⠀⠀⣰⣦⠀⠀⠀⠀⠀⠀⠀⣶⣶⣶⠀⠀⠀⠀⢰⣶⣶⡆⠀⠀⠀⢠⣶⡄⠀⠀⠀⢰⣶⣶⡆⠀⠀⠀⣠⣴⣶⣶⣶⣶⣦⣄⡀⠀⠀⠀⠀⣶⣶⣶⣶⣶⣶⣶⣦⣄⠀⠀⠀⢰⣶⣶⡆⠀⠀⠀⣰⣶⣶⡶⠀⠀⣠⣶⣶⣶⣶⣶⣤⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⢠⠞⠉⣠⡄⠈⠙⠻⣿⣿⣿⣿⣿⣿⣶⣄⠄⠀⠀⠀⠀⠀⠀⠀⢸⣿⣿⡟⠋⠛⠻⣿⠋⠀⠀⢸⣿⣿⠛⠛⠛⠛⠛⠛⠀⠀⠀⠀⠀⣰⣿⣿⣧⠀⠀⠀⠀⠀⠀⣿⣿⣿⠀⠀⠀⠀⠀⢿⣿⣿⠀⠀⢀⣾⣿⣿⡄⠀⠀⣾⣿⣿⠁⠀⣠⣾⣿⣿⠿⠿⠻⠿⣿⣿⣿⣦⠀⠀⠀⣿⣿⣿⠛⠛⠛⠻⣿⣿⣷⠀⠀⢸⣿⣿⡇⠀⣠⣾⣿⡿⠋⠀⠀⣾⣿⣿⠟⠙⠛⠿⡿⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠊⠀⣰⣿⣷⠀⠀⠀⠀⠙⢿⣿⣿⣿⣿⣿⣷⠀⠀⠀⠀⠀⠀⠀⠸⣿⣿⣷⣤⣀⠀⠀⠀⠀⠀⢸⣿⣿⣀⣀⣀⣀⣀⠀⠀⠀⠀⠀⣰⣿⣿⣿⣿⣧⠀⠀⠀⠀⠀⣿⣿⣿⠀⠀⠀⠀⠀⠸⣿⣿⡇⢀⣾⣿⣿⣿⣿⡀⢰⣿⣿⠇⠀⢰⣿⣿⡟⠁⠀⠀⠀⠀⠀⠻⣿⣿⣧⠀⠀⣿⣿⣿⠀⠀⠀⢀⣼⣿⣿⠀⠀⢸⣿⣿⣇⣴⣿⣿⠟⠁⠀⠀⠀⢻⣿⣿⣦⣄⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⣿⣿⣿⣆⠀⠀⠀⠀⠀⠈⠻⣿⣿⣿⣿⠀⠀⠀⠀⠀⠀⠀⠀⠙⠻⢿⣿⣿⣿⣦⡄⠀⠀⢸⣿⣿⣿⣿⣿⣿⣿⠀⠀⠀⠀⣰⣿⣿⠏⠘⣿⣿⣧⠀⠀⠀⠀⣿⣿⣿⠀⠀⠀⠀⠀⠀⢻⣿⣿⣼⣿⡿⠁⢿⣿⣷⣾⣿⡿⠀⠀⢸⣿⣿⡇⠀⠀⠀⠀⠀⠀⠀⣿⣿⣿⠀⠀⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⠀⠀⢸⣿⣿⡿⣿⣿⣧⡀⠀⠀⠀⠀⠀⠙⠿⣿⣿⣿⣷⣦⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠂⠀⣿⣿⣿⣿⡄⠀⠀⠀⠀⠀⠀⠈⢿⣿⡟⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⣿⣿⣿⡄⠀⢸⣿⣿⠀⠀⠀⠀⠀⠀⠀⠀⢰⣿⣿⣯⣤⣤⣽⣿⣿⣆⠀⠀⠀⣿⣿⣿⠀⠀⠀⠀⠀⠀⠈⣿⣿⣿⡿⠁⠀⠈⢿⣿⣿⣿⠃⠀⠀⠘⣿⣿⣷⡀⠀⠀⠀⠀⢀⣴⣿⣿⡟⠀⠀⣿⣿⣿⠉⠙⣿⣿⣧⡀⠀⠀⠀⢸⣿⣿⡇⠙⢿⣿⣿⣄⠀⠀⠀⠀⠀⠀⠀⠈⢻⣿⣿⣷⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠈⢀⢻⣿⣿⣿⣿⣆⠀⠀⠀⠀⠀⠀⠀⡿⠁⠀⠀⠀⠀⠀⠀⠀⢠⣿⣷⣦⣤⣴⣿⣿⡿⠁⠀⢸⣿⣿⣶⣶⣶⣶⣶⣦⠀⢠⣿⣿⡿⠿⠿⠿⠿⠿⣿⣿⣆⠀⠀⣿⣿⣿⣶⣶⣶⣶⣦⠀⠀⢹⣿⣿⠃⠀⠀⠀⠈⢿⣿⡟⠀⠀⠀⠀⠙⢿⣿⣿⣷⣶⣶⣶⣿⣿⣿⠟⠀⠀⠀⣿⣿⣿⠀⠀⠘⣿⣿⣷⡀⠀⠀⢸⣿⣿⡇⠀⠈⠻⣿⣿⣷⡀⠀⣰⣿⣶⣤⣤⣾⣿⣿⡟⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠑⠿⣿⣿⣿⣿⣧⡀⠀⠀⠀⣠⡶⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⠻⠿⠿⠿⠟⠋⠀⠀⠀⠸⠿⠿⠿⠿⠿⠿⠿⠿⠀⠿⠿⠟⠀⠀⠀⠀⠀⠀⠹⠿⠿⠆⠀⠿⠿⠿⠿⠿⠿⠿⠿⠀⠀⠀⠿⠃⠀⠀⠀⠀⠀⠈⠿⠁⠀⠀⠀⠀⠀⠀⠉⠛⠿⠿⠿⠿⠟⠋⠁⠀⠀⠀⠀⠿⠿⠿⠀⠀⠀⠘⠿⠿⠷⠀⠀⠸⠿⠿⠇⠀⠀⠀⠙⠿⠿⠿⠂⠈⠛⠿⠿⠿⠿⠟⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠉⠛⠛⠛⠛⠓⠀⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⠀⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⠀⣀⣀⡀⢀⣆⡀⢀⣀⡀⠀⣀⣀⢀⣀⡀⠀⣀⣀⡀⣰⣀⠀⡀⢀⠀⢀⠀⣀⣀⠀⠀⠀⣀⣀⢀⣰⣀⢀⠀⠀⡀⢀⣀⣸⠀⢀⠀⣀⣀⡀⢀⢀⡀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⠀⡇⠀⢱⠀⡇⠀⢟⠒⠚⠀⡇⠀⢠⠒⡂⢰⡁⠀⡀⢸⠀⠀⡇⠈⣆⠎⠰⡓⠒⠃⠀⠀⠑⢢⠀⢸⠀⢸⡀⢀⡇⢸⠀⢸⠀⢸⠰⡁⠀⡹⠘⠲⡄⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠀⠁⠀⠈⠀⠉⠁⠈⠉⠁⠀⠁⠀⠈⠉⠁⠀⠉⠉⠀⠈⠉⠁⠁⠀⠈⠀⠀⠉⠉⠁⠀⠀⠉⠉⠀⠈⠉⠀⠉⠉⠁⠀⠉⠉⠀⠈⠀⠈⠉⠁⠈⠉⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀`;

export { SEALWORKS_LOGO };
