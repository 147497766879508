<template>
  <!-- orbit loader  -->
  <div class="orbit-loader" v-show="loading">
    <div class="orbit-loader-sun"></div>
    <div class="orbit-loader-ring inner-ring">
      <div class="orbit-loader-planet inner-planet"></div>
    </div>

    <div class="orbit-loader-ring outer-ring">
      <div class="orbit-loader-planet outer-planet"></div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  name: "orbiter-loader",
  setup() {
    const { loading } = storeToRefs(useAppStore());

    return {
      loading,
    };
  },
};
</script>

<style scoped></style>
