import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
      type: "email"
    }, null, 512), [
      [_vModelText, _ctx.username]
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.username.$errors, (error, index) => {
      return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(error.$message), 1))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.password.$errors, (error, index) => {
      return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(error.$message), 1))
    }), 128)),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
      type: "password"
    }, null, 512), [
      [_vModelText, _ctx.password]
    ]),
    _createElementVNode("button", {
      class: "button-primary",
      disabled: _ctx.v$.$invalid,
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)))
    }, " Sign In ", 8, _hoisted_3)
  ]))
}