
import { defineComponent, reactive, watchEffect } from "vue";
import AssigneePicker from "@/components/assignees/assignee-picker.vue";
import { ProjectFilter } from "@/types";

export default defineComponent({
  name: "ProjectFilter",
  props: {
    assignees: {
      type: Object,
    },
    modelValue: {
      type: Object,
    },
  },
  components: {
    AssigneePicker,
  },
  setup(props, { emit }) {
    const filter = reactive({
      assignee: undefined,
    } as ProjectFilter);

    watchEffect(() => emit("update:modelValue", filter));

    return {
      filter,
    };
  },
});
