
import { computed, defineComponent, PropType } from "vue";
import { ProjectGroup, ProjectFilter } from "@/types";
import Task from "@/components/tasks/Task.vue";

export default defineComponent({
  name: "ProjectGroup",
  props: {
    group: {
      type: Object as PropType<ProjectGroup>,
      required: true,
    },
    filter: {
      type: Object as PropType<ProjectFilter>,
      required: false,
    },
  },
  components: {
    Task,
  },
  setup(props) {
    const filteredTasks = computed(() =>
      props.group?.tasks?.filter((task) =>
        props.filter?.assignee
          ? task.assignee?.assignee_list.includes(props.filter.assignee)
          : task
      )
    );

    return {
      filteredTasks,
    };
  },
});
