
import { defineComponent, toRefs } from "vue";
import { useTaskListStore } from "@/store/task-list";
import { storeToRefs } from "pinia";
import TaskAssignee from "@/components/tasks/TaskAssignee.vue";
import TaskMenu from "@/components/tasks/TaskMenu.vue";
import Task from "@/components/tasks/Task.vue";
import ProjectImage from "@/components/projects/ProjectImage.vue";
import { useFormat } from "@/composables/use-format";
import TaskNotification from "@/components/tasks/TaskNotification.vue";

export default defineComponent({
  name: "TaskListRecent",
  components: {
    Task,
  },

  setup() {
    const tasksListStore = useTaskListStore();

    const { tasks } = storeToRefs(tasksListStore);

    const { formatDueDate, formatTimeStamp, formatAsDateTime } = useFormat();

    tasksListStore.findRecent(5);

    return {
      tasks,
      // Methods
      formatDueDate,
      formatTimeStamp,
      formatAsDateTime,
    };
  },
});
