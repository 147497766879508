import { defineStore } from "pinia";
import { Universe } from "@/types";
import { ref } from "vue";
import { api } from "@/api/client";

export const useUniverseStore = defineStore("universe", () => {
  const universes = ref<Array<Universe>>(new Array<Universe>());

  const fetch = async () => {
    universes.value = await api.get<Array<Universe>>(`universes/`);
  };

  return {
    universes,

    fetch,
  };
});
