import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import { messages as notificationMessages } from "@/types/notifications";

const i18n = createI18n({
  locale: "en",
  messages: {
    ...notificationMessages,
  },
});

import { createPinia } from "pinia";
import { SEALWORKS_LOGO } from "@/types/constants";

const pinia = createPinia();

createApp(App).use(pinia).use(router).use(i18n).mount("#app");

console.log(SEALWORKS_LOGO);
