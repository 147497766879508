import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { api } from "@/api/client";
import { TimeTracking, TimeTrackingResult } from "@/types";
import useUserStore from "@/store/user";

export const useTimeTracking = defineStore("timeTracking", () => {
  const userStore = useUserStore();

  const taskTimeTracking = ref<Array<TimeTracking>>(Array<TimeTracking>());

  const isRunningForUser = (userPk: number) => {
    return taskTimeTracking.value.some(
      (taskTimeTracking) =>
        taskTimeTracking.user === userPk && taskTimeTracking.running
    );
  };

  const active = computed(() =>
    taskTimeTracking.value.filter((timeTracking) => timeTracking.running)
  );

  const isAnyActive = computed(() => active.value.length > 0);

  const isRunning = computed(() => isRunningForUser(userStore.user.pk));

  const start = async (pk: number) => {
    const timeTrackingResult = await api.put<unknown, TimeTrackingResult>(
      `tasks/${pk}/start/`
    );
  };

  const stop = async (pk: number) => {
    const timeTrackingResult = await api.put<unknown, TimeTrackingResult>(
      `tasks/${pk}/stop/`
    );
  };

  const fetchTimeSpent = async (pk: number) => {
    taskTimeTracking.value = await api.get<Array<TimeTracking>>(
      `tasks/${pk}/time-spent/`
    );
  };

  return {
    taskTimeTracking,
    active,
    isAnyActive,
    isRunning,
    isRunningForUser,
    start,
    stop,
    fetch,
    fetchTimeSpent,
  };
});
