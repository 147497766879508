import { defineStore } from "pinia";
import { api } from "@/api/client";
import { Task } from "@/types";
import { ref } from "vue";

export const useTaskListStore = defineStore("taskList", () => {
  const tasks = ref<Array<Task>>(new Array<Task>());

  const findRecent = async (max: number) => {
    tasks.value = await api.get<Array<Task>>(`tasks/?filter=recent`);
  };

  const markAsChanged = (...pk: number[]) => {
    console.log("markAsChanged", pk);
    findByPk(...pk).map((t) => (t._changed = true));
  };

  const fetchTask = async (pk: number) => {
    return await api.get<Task>(`tasks/${pk}/`);
  };

  const findByWorklog = async (max: number) => {
    tasks.value = await api.get<Array<Task>>(`tasks/?filter=worklog`);
  };

  const findByPk = (...pk: number[]) => {
    return tasks.value.filter(
      (task) => task.pk !== undefined && pk.includes(task.pk)
    );
  };
  /*
      watch(socket.data, async (message) => {
        const payload = JSON.parse(message);
        const task = findByPk(payload.pk);
        if (payload.event === "assignee_changed") {
          task.assignee = payload.data;
        } else if (payload.event === "status_changed") {
          task.status = payload.data;
        }
      });*/

  return {
    tasks,
    fetchTask,
    findRecent,
    findByWorklog,
    markAsChanged,
  };
});
