import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2025c90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lc-input" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "text",
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = (event) => _ctx.$emit('update:modelValue', event.target.value))
    }, null, 40, _hoisted_2)
  ]))
}