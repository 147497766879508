import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_task = _resolveComponent("task")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "task-card"
      }, [
        _createVNode(_component_task, { task: task }, null, 8, ["task"])
      ]))
    }), 128))
  ]))
}