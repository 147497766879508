
import { defineComponent, ref } from "vue";
import { useProjectStore, useTaskStore } from "@/store";
import TaskPriority from "@/components/tasks/TaskPriority.vue";
import TaskMenu from "@/components/tasks/TaskMenu.vue";
import Editor from "@/components/Editor.vue";
import TaskAssigneeSelect from "@/components/tasks/TaskAssigneeSelect.vue";
import TaskTimeTracking from "@/components/tasks/TaskTimeTracking.vue";
import TaskObserver from "@/components/tasks/TaskObserver.vue";
import TaskComments from "@/components/tasks/TaskComments.vue";
import { useFormat } from "@/composables/use-format";

import { storeToRefs } from "pinia";
import { useDebounceFn } from "@vueuse/shared";
import LcInput from "@/components/LcInput.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Task",
  components: {
    TaskPriority,
    TaskMenu,
    TaskObserver,
    TaskAssigneeSelect,
    TaskTimeTracking,
    TaskComments,
    Editor,
    LcInput,
  },
  props: {
    msg: String,
    taskPk: { type: String, required: false },
    projectPk: { type: String, required: true },
  },

  setup(props) {
    const router = useRouter();

    const taskStore = useTaskStore();

    const projectStore = useProjectStore();

    const { updateTask, saveTask } = taskStore;
    const { projectAssignees } = storeToRefs(projectStore);
    const { task, totalTimeSpent } = storeToRefs(taskStore);
    const {
      formatDueDate,
      formatTimeStamp,
      formatAsDateTime,
      formatAsDuration,
    } = useFormat();

    const comment = ref("");
    const assigneeHasChanged = ref(false);

    projectStore.fetchProjectAssignees(props.projectPk);

    if (props.taskPk) {
      taskStore.fetchTask(props.taskPk);
    } else {
      taskStore.createTask(props.projectPk);
    }

    const updateAssignee = (assigneePk: number) => {
      taskStore.updateAssignee(assigneePk);
    };

    const debouncedSave = useDebounceFn(
      async () => {
        await taskStore.updateTask({
          key: task.value.key,
          description: task.value.description,
        });
      },
      1000,
      { maxWait: 3000 }
    );

    const updateDescription = () => {
      debouncedSave();
    };

    const onCreate = async () => {
      await saveTask();
      await router.replace({
        name: "Task",
        params: { projectPk: props.projectPk, taskPk: task.value?.key },
      });
    };

    return {
      // Refs
      task,
      totalTimeSpent,
      assigneeHasChanged,
      comment,
      projectAssignees,

      // Methods
      onCreate,
      updateAssignee,
      updateDescription,
      updateTask,

      formatDueDate,
      formatTimeStamp,
      formatAsDateTime,
      formatAsDuration,
    };
  },
});
