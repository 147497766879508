import dayjs from "dayjs";
import Duration from "dayjs/plugin/duration";
import RelativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(Duration);
dayjs.extend(RelativeTime);
dayjs.extend(LocalizedFormat);

function formatTimeStamp(date: Date) {
  return dayjs().to(dayjs(date));
}

function formatAsDateTime(date: Date) {
  return dayjs(date).format("llll");
}

function formatDueDate(date: Date) {
  return dayjs().to(dayjs(date));
}

function formatAsDuration(seconds: number) {
  if (seconds === undefined || seconds < 10) {
    return "";
  }
  return dayjs.duration(seconds, "seconds").humanize();
}

export const useFormat = () => {
  return {
    formatAsDateTime,
    formatTimeStamp,
    formatDueDate,
    formatAsDuration,
  };
};
