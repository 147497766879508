import { defineStore } from "pinia";
import { ref } from "vue";
import { api } from "@/api/client";
import { Comment } from "@/types";

export const useCommentStore = defineStore("comments", () => {
  const comments = ref<Array<Comment>>(new Array<Comment>());

  const fetch = async (taskPk: number|string) => {
    comments.value = await api.get<Array<Comment>>(`tasks/${taskPk}/comments/`);
  };

  const add = async (taskPk: string|number, text: string) => {
    const comment = await api.post<Partial<Comment>, Comment>(
      `tasks/${taskPk}/comments/`,
      {
        text: text,
      }
    );
    comments.value.unshift(comment);
  };

  return {
    comments,
    fetch,
    add,
  };
});
