
import {defineComponent, ref, watch} from "vue";
import {useProjectStore} from "@/store/projects";
import {storeToRefs} from "pinia";

export default defineComponent({
  name: "ProjectNav",
  emits: ["layout:change"],
  setup(props, {emit}) {
    const projectStore = useProjectStore();
    const {projectFolders, starredProjects} = storeToRefs(projectStore);

    const isAccessorySidebarVisible = ref(false);


    watch(() => isAccessorySidebarVisible.value, () => {
      console.log("layout:change");
      emit("layout:change", {isAccessorySidebarVisible: isAccessorySidebarVisible.value});
    })

    projectStore.fetchAll();
    projectStore.fetchFolders();


    return {
      projectStore,
      projectFolders,
      starredProjects,
      isAccessorySidebarVisible,
    };
  },
});
