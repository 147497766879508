
import "bootstrap";
import {storeToRefs} from "pinia";
import {useUserStore} from "@/store";
import OrbiterLoader from "@/components/global/orbiter-loader.vue";
import Header from "@/components/layouts/Header.vue";
import ProjectNav from "@/components/layouts/ProjectNav.vue";
import LayoutMain from "@/components/layouts/LayoutMain.vue";
import {ref} from "vue";

export default {
  components: {OrbiterLoader, Header, ProjectNav, LayoutMain},
  setup() {
    const {isSignedIn} = storeToRefs(useUserStore());
    const sidebar = ref("col-2")
    const main = ref("col-10")

    const onLayoutChange = (event: any) => {
      sidebar.value = event.isAccessorySidebarVisible ? "col-4" : "col-2";
      main.value = event.isAccessorySidebarVisible ? "col-8" : "col-10";
    }

    return {
      isSignedIn,
      onLayoutChange,
      sidebar,
      main,
    };
  },
};
