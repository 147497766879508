import Axios, { AxiosInstance } from "axios";
import { TokenPair, User } from "@/types";
import { useAppStore, useErrorStore, useUserStore } from "@/store";
import router from "@/router";
import { useSettings } from "@/composables/use-settings";

export interface IAPIClient {
  post<TRequest, TResponse>(
    path: string,
    object: TRequest,
    shared?: boolean
  ): Promise<TResponse>;
}

Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

class APIClient implements IAPIClient {
  protected client: AxiosInstance;
  protected baseURL: string;

  protected createAxiosClient(): AxiosInstance {
    const client = Axios.create({
      baseURL: `${this.baseURL}/api/v1`,
      responseType: "json" as const,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      timeout: 10 * 1000,
    });
    client.interceptors.response.use(
      (response) => {
        const app = useAppStore();
        app.loading = false;
        return response;
      },
      async (error) => {
        // TODO: left here to catch expired sessions and trigger a re-login
        const requestConfig = error.config;
        const status = error.response.status;

        const app = useAppStore();
        app.loading = false;

        const isSignInRequest = requestConfig.url.endsWith("auth/login/");

        console.log(
          "response-interceptor",
          JSON.stringify({ isSignInRequest, status })
        );
        // Auth failed
        if (status === 400 && !isSignInRequest) {
          // Check if this is a failed refresh token request
        }

        return Promise.reject(error);
      }
    );
    return client;
  }

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.client = this.createAxiosClient();
  }

  getTokenFromLocalStorage(): TokenPair {
    const token = window.localStorage.getItem("lc_token");
    if (token) {
      return JSON.parse(token) as TokenPair;
    }
    return {
      access_token: "",
      refresh_token: "",
      user: {} as User,
    } as TokenPair;
  }

  async get<TResponse>(path: string): Promise<TResponse> {
    const errorStore = useErrorStore();
    try {
      const response = await this.client.get<TResponse>(path);
      return response.data;
    } catch (error) {
      errorStore.handleError(error);
    }
    return {} as TResponse;
  }

  async post<TRequest, TResponse>(
    path: string,
    payload?: TRequest
  ): Promise<TResponse> {
    const errorStore = useErrorStore();
    try {
      const response = await this.client.post<TResponse>(path, payload);
      return response.data;
    } catch (error) {
      errorStore.handleError(error);
      return Promise.reject(error);
    }
  }

  async patch<TRequest, TResponse>(
    path: string,
    payload: TRequest
  ): Promise<TResponse> {
    const errorStore = useErrorStore();
    try {
      const response = await this.client.patch<TResponse>(path, payload);
      return response.data;
    } catch (error) {
      errorStore.handleError(error);
    }
    return {} as TResponse;
  }

  async put<TRequest, TResponse>(
    path: string,
    payload?: TRequest
  ): Promise<TResponse> {
    const errorStore = useErrorStore();
    try {
      const response = await this.client.put<TResponse>(path, payload);
      return response.data;
    } catch (error) {
      errorStore.handleError(error);
    }
    return {} as TResponse;
  }
}

class PrivateAPIClient extends APIClient {
  constructor(baseURL: string) {
    super(baseURL);
    this.client.interceptors.request.use(async (config) => {
      const settings = useSettings();
      const app = useAppStore();
      app.loading = true;

      console.log("interceptor.request", settings.tenant.value);
      /*
      if (config.headers) {
        config.headers["x-tenant"] = settings.tenant.value;
      }*/

      return config;
    });
  }
}

const sharedApi = new APIClient(process.env.VUE_APP_API_URL);
const api = new PrivateAPIClient(process.env.VUE_APP_API_URL);

export { sharedApi, api };
