import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "avatar"
}
const _hoisted_2 = ["title", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.avatar)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createElementVNode("img", {
          title: _ctx.avatar.title,
          src: _ctx.avatar.base64
        }, null, 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}