
import { computed, defineComponent, reactive, watch } from "vue";
import { useProjectStore } from "@/store";
import ProjectGroup from "@/components/projects/ProjectGroup.vue";
import ProjectFilter from "@/components/projects/ProjectFilter.vue";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "Project",
  props: {
    projectKey: {
      type: String,
      required: true,
    },
  },
  components: {
    ProjectGroup,
    ProjectFilter,
  },
  setup(props) {
    const projectStore = useProjectStore();

    const { project, projectAssignees } = storeToRefs(projectStore);

    const filter = reactive({});

    watch(
      () => props.projectKey,
      () => {
        projectStore.fetch(props.projectKey);
      },
      { immediate: true }
    );

    return {
      filter,
      project,
      projectAssignees,
    };
  },
});
