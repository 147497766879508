// @see https://www.qupaya.com/blog/type-safe-local-storage

import { computed } from "vue";

type Settings = "lc.tenant" | "lc.user_id";

class SettingsService {
  setItem = (key: Settings, value: unknown): void => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  getItem<T>(key: Settings): T | null;
  getItem<T>(key: Settings, otherwise: T): T;
  getItem<T>(key: Settings, otherwise?: T): T | null {
    const data: string | null = window.localStorage.getItem(key);
    if (data !== null) {
      return JSON.parse(data);
    }

    if (otherwise) {
      return otherwise;
    }

    return null;
  }
}

const useSettings = () => {
  const localStorage = new SettingsService();

  const tenant = computed<string>({
    get() {
      return localStorage.getItem<string>("lc.tenant", "");
    },
    set(tenant) {
      localStorage.setItem("lc.tenant", tenant);
    },
  });

  return {
    tenant,
  };
};

export { useSettings };
