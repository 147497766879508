
import {computed} from "vue";

export default {
  name: "layout-main",
  emits: ["layout:changed"],
  props: {
    sidebar: {
      type: String,
      default: "col-2",
    },
    main: {
      default: "col-10",
    }
  },
}
