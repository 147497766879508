
import { computed, defineComponent, PropType } from "vue";
import { TaskObserver } from "@/types";

export default defineComponent({
  name: "TaskObserver",
  props: {
    observers: {
      type: Array as PropType<Array<TaskObserver>>,
      required: false,
      default: () => new Array<TaskObserver>(),
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const count = computed(() => props.observers?.length || 0);
    const users = computed(() =>
      props.observers.map((observer) => observer.user.display_name)
    );
    return {
      count,
      users,
    };
  },
});
