import { defineStore, storeToRefs } from "pinia";
import { api } from "@/api/client";
import {
  Assignee,
  Task,
  TaskObserver,
  TimeTracking,
  Todo,
  User,
  Comment,
  Project,
} from "@/types";
import { computed, reactive, toRefs } from "vue";
import useUserStore from "@/store/user";
import { useProjectStore } from "@/store/projects";

export const useTaskStore = defineStore("taskStore", () => {
  const projectStore = useProjectStore();

  const state = reactive({
    task: {
      todos: [] as Array<Todo>,
      task_observers: [] as Array<TaskObserver>,
      time_tracking: [] as Array<TimeTracking>,
      comments: [] as Array<Comment>,
    } as Partial<Task>,
    userTimeSpent: 0,
  });

  const { user } = storeToRefs(useUserStore());

  const createTask = async (projectPk: string) => {
    const project = await projectStore.fetchPromise(projectPk);
    state.task = {
      project_group: project.default_group.pk,
      _changed: false,
    } as Task;
  };

  const fetchTask = async (pk: string) => {
    state.task = await api.get<Task>(
      `task/${pk}/?expand=comments,todos,time_tracking,task_observers`
    );
    state.userTimeSpent = userTimeTracking.value.time_spent || 0;
  };

  const totalTimeSpent = computed(() =>
    state.task?.time_tracking?.reduce(
      (prevValue, taskTimeTracking) => prevValue + taskTimeTracking.time_spent,
      0
    )
  );

  const userTimeTracking = computed(
    () =>
      state.task.time_tracking?.find(
        (taskTimeTracking) => taskTimeTracking.user === user.value.pk
      ) || ({} as TimeTracking)
  );

  const start = async () => {
    state.task = await api.put<unknown, Task>(`tasks/${state.task.pk}/start/`);
  };

  const stop = async () => {
    state.task = await api.put<unknown, Task>(`tasks/${state.task.pk}/stop/`);
  };

  const updateAssignee = async (assigneePk: number) => {
    if (state.task) {
      state.task.assignee = {
        pk: assigneePk,
      } as Assignee;
      state.task = await api.post<undefined, Task>(
        `tasks/${state.task.pk}/assign/${assigneePk}/?expand=*`
      );
    }
  };

  const updateStatus = async (statusPk: number) => {
    if (state.task) {
      state.task.status = statusPk;
      state.task = await api.post<undefined, Task>(
        `tasks/${state.task.pk}/change-status/${statusPk}/`
      );
    }
  };

  const updateTask = async (task: Partial<Task>) => {
    if (task.key) {
      state.task = await api.patch(`task/${task.key}/?expand=*`, task);
    } else {
      state.task = { ...state.task, ...task };
    }
  };

  const saveTask = async () => {
    state.task = await api.post(`task/?expand=*`, state.task);
  };

  return {
    ...toRefs(state),
    // Actions
    createTask,
    fetchTask,
    saveTask,
    start,
    stop,
    updateAssignee,
    updateStatus,
    updateTask,

    // Getters
    userTimeTracking,
    totalTimeSpent,
  };
});
