import { defineStore } from "pinia";
import { Notification, NotificationMessage } from "@/types";
import { useWebSocket } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { useTaskListStore } from "@/store/task-list";
import { api } from "@/api/client";
import useUserStore from "@/store/user";

export const useNotificationStore = defineStore("notifications", () => {
  const unseenNotificationsCount = ref<number>(0);
  const notifications = ref<Array<Notification>>(new Array<Notification>());

  const taskListStore = useTaskListStore();
  const userStore = useUserStore();

  const accountId = computed(() => userStore.user?.account?.pk);

  const { data, status } = useWebSocket(
    `${process.env.VUE_APP_SOCKETS_URL}/ws/notifications/?${accountId.value}`,
    { heartbeat: false, immediate: true }
  );

  watch(
    () => status.value,
    (status) => {
      console.log("websocket status changed", status);
    }
  );

  const fetchUnseenCount = async () => {
    unseenNotificationsCount.value = await api.get<number>(
      `notifications/count/`
    );
  };

  const fetchUnseen = async () => {
    notifications.value = await api.get<Array<Notification>>(`notifications/`);
  };

  watch(data, (message) => {
    const payload = JSON.parse(message) as NotificationMessage;
    unseenNotificationsCount.value += payload.notifications.length;

    const events = payload.notifications.map((n) => n.activity.event);

    const sources = events.map((event) => event.source) as number[];

    taskListStore.markAsChanged(...sources);
  });

  return {
    unseenNotificationsCount,
    notifications,
    fetchUnseen,
    fetchUnseenCount,
  };
});
