
import { computed, defineComponent, onUnmounted, PropType } from "vue";
import UserAvatar from "@/components/users/UserAvatar.vue";
import { Task } from "@/types";
import { useTaskStore } from "@/store";
import { useFormat } from "@/composables/use-format";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "TaskTimeTracking",
  components: { UserAvatar },
  props: {
    task: { type: Object as PropType<Task>, required: true },
  },
  setup(props) {
    const taskStore = useTaskStore();
    const { userTimeSpent } = storeToRefs(taskStore);
    const { formatAsDuration } = useFormat();

    const timeTrackingUsers = computed(() =>
      props.task.time_tracking?.filter((t) => t.running).map((t) => t.user)
    );

    const isRunning = computed({
      get: () => taskStore.userTimeTracking?.running,
      set: (val) => {
        if (val === true) {
          taskStore.start();
        } else {
          taskStore.stop();
        }
      },
    });

    const isAnyActive = computed(() => timeTrackingUsers?.value?.length > 0);

    let intervalId = setInterval(() => {
      taskStore.userTimeSpent += 5;
    }, 5000);

    onUnmounted(() => clearInterval(intervalId));

    return {
      isRunning,
      isAnyActive,
      timeTrackingUsers,
      userTimeSpent,
      formatAsDuration,
    };
  },
});
