import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: "project-image",
    "aria-hidden": "true",
    src: _ctx.project.avatar_thumbnail
  }, null, 8, _hoisted_1))
}