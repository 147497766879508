import { defineStore } from "pinia";
import {Assignee, Project, ProjectAssignees, ProjectFolder, ProjectGroup} from "@/types";
import { api } from "@/api/client";
import { computed, reactive, toRefs } from "vue";

export const useProjectStore = defineStore("projects", () => {
  const state = reactive({
    project: {} as Project,
    projects: [] as Array<Project>,
    starredProjects: []as Array<Project>,
    projectAssignees: {} as ProjectAssignees,
    projectGroups: [] as Array<ProjectGroup>,
    projectFolders: [] as Array<ProjectFolder>,
  });

  const defaultProjectGroup = computed(() => state.project?.default_group);

  const fetchProjectGroups = async (projectPk: number) => {
    state.projectGroups = await api.get<Array<ProjectGroup>>(
      `projects/${projectPk}/groups/`
    );
  };

  const fetchProjectAssignees = async (projectPk: string) => {
    state.projectAssignees = await api.get<ProjectAssignees>(
      `projects/${projectPk}/assignees/`
    );
  };

  const fetchPromise = async (key: string | number): Promise<Project> => {
    return api.get<Project>(`projects/${key}/?expand=*`);
  };

  const fetch = async (key: string | number) => {
    state.project = await fetchPromise(key);
  };

  const fetchAll = async () => {
    const url = "projects/";

    state.projects = await api.get<Array<Project>>(url);
  };

  const fetchFolders = async () => {
    const url = "project-folders/?expand=*";

    state.projectFolders = await api.get<Array<ProjectFolder>>(url);
  };

  return {
    ...toRefs(state),
    defaultProjectGroup,
    fetchProjectGroups,
    fetchProjectAssignees,
    fetchAll,
    fetch,
    fetchPromise,
    fetchFolders,
  };
});
