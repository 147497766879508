
import { computed, defineComponent, ref } from "vue";
import Editor from "@/components/Editor.vue";
import { useCommentStore } from "@/store";

export default defineComponent({
  name: "TaskComments",
  components: { Editor },
  props: {
    taskPk: { type: [String, Number], required: true },
  },
  setup(props) {
    const commentStore = useCommentStore();
    const comment = ref<string>("");
    const comments = computed(() => commentStore.comments);

    commentStore.fetch(props.taskPk);

    const onSave = () => {
      commentStore.add(props.taskPk, comment.value);
    };

    return {
      comment,
      comments,

      onSave,
    };
  },
});
