import { defineStore } from "pinia";
import { reactive, toRefs } from "vue";

export const useErrorStore = defineStore("errors", () => {
  const state = reactive({
    error: {} as Error,
  });

  const handleError = (error: Error) => {
    state.error = error;
  };

  return {
    ...toRefs(state),
    handleError,
  };
});
