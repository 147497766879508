import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useUserStore } from "@/store";

import Task from "@/views/Task.vue";
import Login from "@/views/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import Project from "@/views/Project.vue";
import NotFound from "@/views/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },

  {
    path: "/tasks/:projectPk/:taskPk",
    name: "Task",
    component: Task,
    props: true,
  },

  {
    path: "/tasks/:projectPk",
    name: "CreateTask",
    component: Task,
    props: true,
  },

  {
    path: "/projects/:projectKey",
    name: "Project",
    component: Project,
    props: true,
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Login,
    meta: {
      public: true,
    },
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const user = useUserStore();

  await user.fetchUser();

  if (!user.isSignedIn) {
    if (to.meta?.public === true) {
      return next();
    } else {
      return next(`/login?next=${encodeURIComponent(to.fullPath)}`);
    }
  }
  return next();
});

export default router;
