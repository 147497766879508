
import { defineComponent, PropType } from "vue";
import { Project } from "@/types";

export default defineComponent({
  name: "ProjectImage",
  props: {
    project: {
      type: Object as PropType<Project>,
    },
  },
});
