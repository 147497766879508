
import { computed, defineComponent } from "vue";
import { useUserStore } from "@/store";

export default defineComponent({
  name: "UserAvatar",
  props: {
    userPk: { type: Number, required: true },
  },
  setup(props) {
    const userStore = useUserStore();

    userStore.fetchAvatar(props.userPk);

    const avatar = computed(() => userStore.avatarCache[props.userPk]);

    return {
      avatar,
    };
  },
});
