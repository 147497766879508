
import { defineComponent, watch } from "vue";

export default defineComponent({
  name: "TaskAssignee",
  props: {
    assignee: { type: Object },
  },
  setup(props) {
    watch(
      () => props.assignee,
      (assignee, before) => {
        console.log(
          "assignee changed",
          JSON.stringify(assignee),
          JSON.stringify(before)
        );
      }
    );
  },
});
