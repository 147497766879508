
import { computed, defineComponent, ref, watch } from "vue";
import { useUserStore } from "@/store";
import { useFormat } from "@/composables/use-format";
import ProjectImage from "@/components/projects/ProjectImage.vue";
import TaskAssignee from "@/components/tasks/TaskAssignee.vue";
import TaskMenu from "@/components/tasks/TaskMenu.vue";
import TaskNotification from "@/components/tasks/TaskNotification.vue";

export default defineComponent({
  name: "Task",
  props: {
    task: { type: Object, required: true },
  },
  components: {
    ProjectImage,
    TaskAssignee,
    TaskMenu,
    TaskNotification,
  },
  setup(props) {
    const enabled = ref(true);
    const status = computed(() => props.task.status);
    const assignee = computed(() => props.task.assignee);
    const comments = computed(() => props.task.comments);

    const user = useUserStore();

    const { formatDueDate, formatTimeStamp, formatAsDateTime } = useFormat();

    watch(status, (status) => {
      // Status Changed
      console.log(JSON.stringify(status));
    });

    watch(assignee, (assignee) => {
      // Assignee Changed
      if (assignee.assignee_list.indexOf(user.user.pk) === -1) {
        console.log("you have been removed as an assignee");
        enabled.value = false;
      } else {
        console.log("you are still assigned");
        enabled.value = true;
      }
    });

    watch(comments, (comments) => {
      console.log("comments", comments);
    });

    return {
      enabled,
      formatDueDate,
      formatTimeStamp,
      formatAsDateTime,
    };
  },
});
