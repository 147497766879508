import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-160dc000"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: "task-notification",
        title: _ctx.message
      }, "   ", 8, _hoisted_1), [
        [_vShow, _ctx.isNotified]
      ])
    ]),
    _: 1
  }))
}