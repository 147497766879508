import Axios from "axios";
import { User } from "@/types";
import { useErrorStore, useUserStore } from "@/store";

Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const client = Axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
  responseType: "json" as const,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  timeout: 10 * 1000,
});

type LoginResult = {
  success: boolean;
  reason?: string;
};

const login = async (
  username: string,
  password: string
): Promise<LoginResult> => {
  const userStore = useUserStore();

  try {
    userStore.user = await client.post<Record<string, string>, User>(
      "auth/login/",
      {
        username,
        password,
      }
    );
  } catch (e) {
    return { success: false, reason: e.response?.data?.detail } as LoginResult;
  }
  return { success: true } as LoginResult;
};

const logout = async () => {
  const errorStore = useErrorStore();
  const userStore = useUserStore();

  try {
    await client.post("auth/logout");
    userStore.clear();
  } catch (e) {
    errorStore.error = e;
  }
};

export { login, logout };
