
import {defineComponent, reactive, toRefs} from "vue";

import {useUserStore} from "@/store";
import {useVuelidate} from "@vuelidate/core";
import {Router, useRouter} from "vue-router";
import {email, required} from "@vuelidate/validators";
import {login as auth_login, logout as auth_logout} from "@/api/auth";
import {useSettings} from "@/composables/use-settings";

function redirectNext(router: Router) {
  const params = new URLSearchParams(window.location.search);

  let next = params.get("next");
  if (!next) {
    return router.push({ name: "Dashboard" });
  }

  return router.push(next);
}

export default defineComponent({
  name: "Login",
  props: {
    msg: String,
  },

  setup() {
    const userStore = useUserStore();
    const settings = useSettings();
    const router = useRouter();

    const state = reactive({
      username: "",
      password: "",
      error: "",
    });

    const rules = {
      username: { required, email },
      password: { required },
    };

    const v$ = useVuelidate(rules, state, {
      $autoDirty: true,
    });

    if (router.currentRoute.value.name === "Logout") {
      auth_logout();
      userStore.clear();
    }

    const login = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        return;
      }
      try {
        const result = await auth_login(state.username, state.password);
        if (!result.success) {
          state.error = result.reason ? result.reason : "Unknown Error";
        } else {
          await redirectNext(router);
        }
      } catch (error) {
        console.log("error!", JSON.stringify({ error }));
      }
    };

    return {
      ...toRefs(state),
      v$,
      login,
    };
  },
});
